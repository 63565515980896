import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AiCompletionFilters } from '@/modules/accounts/types.ts';
import { buildQueryParams } from '@/shared/utils/buildQueryParams.ts';

interface AiCompletionResponse {
	id: number;
	account_id: number;
	integration_id: number;
	project_id: number;
	entity_id: number;
	entity_type_id: number;
	ai_prompt_id: number;
	prompt_tokens: number;
	completion_tokens: number;
	total_tokens: number;
	created_at: string;
	updated_at: string;
	finished_at: string;
	result: any[];
	status: number;
	restart_attempts: number;
	hash: string;
	ai_proxy_task_id: number;
}

export const aiCompletionsApi = createApi({
	reducerPath: 'aiCompletionsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['AiCompletions'],
	endpoints: (builder) => ({
		// Получение метаинформации для AI-обработки
		getAiCompletionsMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.AI_COMPLETION.META,
		}),

		// Получение списка обработок для проекта
		getAiCompletions: builder.query<
			{ completions: AiCompletionResponse[]; total: number; page: number; limit: number },
			{
				account_id: string | number;
				project_id: string | number;
				limit?: number;
				page?: number;
			} & AiCompletionFilters
		>({
			query: (filters) => {
				const { account_id, project_id } = filters;

				if (!account_id && !project_id) {
					throw new Error(
						'account_id and project_id is required to fetch AI completions',
					);
				}

				const filterRules = {
					page: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					ids: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.ids) params.append('ids', filters.ids.toString());
					},
					call_ids: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.call_ids)
							params.append('call_ids', filters.call_ids.toString());
					},
					entity_type_id: (
						filters: Partial<AiCompletionFilters>,
						params: URLSearchParams,
					) => {
						if (filters.entity_type_id)
							params.append('entity_type_id', filters.entity_type_id.toString());
					},
					hash: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.hash) params.append('hash', filters.hash.toString());
					},
					status: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.status) params.append('status', filters.status.toString());
					},
					created_at: (
						filters: Partial<AiCompletionFilters>,
						params: URLSearchParams,
					) => {
						if (filters.created_at)
							params.append('created_at', filters.created_at.toString());
					},
					updated_at: (
						filters: Partial<AiCompletionFilters>,
						params: URLSearchParams,
					) => {
						if (filters.updated_at)
							params.append('updated_at', filters.updated_at.toString());
					},
					finished_at: (
						filters: Partial<AiCompletionFilters>,
						params: URLSearchParams,
					) => {
						if (filters.finished_at)
							params.append('finished_at', filters.finished_at.toString());
					},
					sort: (filters: Partial<AiCompletionFilters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				const queryParams = buildQueryParams(filters, filterRules);
				const url = `${API_ENDPOINTS.AI_COMPLETION.LIST(account_id, project_id)}?${queryParams.toString()}`;
				return { url };
			},
			providesTags: (result) =>
				result?.completions
					? [
							...result.completions.map(({ id }: AiCompletionResponse) => ({
								type: 'AiCompletions' as const,
								id,
							})),
							{ type: 'AiCompletions', id: 'LIST' },
						]
					: [{ type: 'AiCompletions', id: 'LIST' }],
		}),

		// Получение данных обработки по ID
		getAiCompletionById: builder.query<
			AiCompletionResponse,
			{
				account_id: string | number;
				project_id: string | number;
				ai_completion_id: string | number;
			}
		>({
			query: ({ account_id, project_id, ai_completion_id }) => {
				// Проверяем, что все необходимые параметры есть
				if (!account_id || !project_id || !ai_completion_id) {
					return { url: '' }; // Не выполняем запрос, возвращаем пустой URL
				}
				return API_ENDPOINTS.AI_COMPLETION.BY_ID(account_id, project_id, ai_completion_id);
			},
			providesTags: (_result, _error, { ai_completion_id }) => {
				// Проверяем, что ai_completion_id не пустой
				if (!ai_completion_id) {
					return [];
				}
				return [{ type: 'AiCompletions', id: ai_completion_id }];
			},
		}),
	}),
});

export const {
	useGetAiCompletionsMetaQuery,
	useGetAiCompletionsQuery,
	useGetAiCompletionByIdQuery,
} = aiCompletionsApi;
