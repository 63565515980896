import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Account, Filters } from '@/modules/accounts/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';

// API для работы с аккаунтами
export const accountsApi = createApi({
	reducerPath: 'accountsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Accounts'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы аккаунтов
		getAccountsMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.ACCOUNT.META,
		}),

		// Получение списка аккаунтов с фильтрацией, сортировкой и пагинацией
		getAccounts: builder.query<
			{ accounts: Account[]; total: number; page: number; limit: number },
			Partial<Filters>
		>({
			query: (filters) => {
				const filterRules = {
					name: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.name) params.append('name', filters.name);
					},
					status: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.status) params.append('status', filters.status.toString());
					},
					page: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				const queryParams = buildQueryParams(filters, filterRules);

				return {
					url: `${API_ENDPOINTS.ACCOUNT.LIST}${queryParams}`,
				};
			},
			providesTags: (result) =>
				result?.accounts
					? [
							...result.accounts.map(({ id }: Account) => ({
								type: 'Accounts' as const,
								id,
							})),
							{ type: 'Accounts', id: 'LIST' },
						]
					: [{ type: 'Accounts', id: 'LIST' }],
		}),

		// Получение данных аккаунта по ID
		getAccountById: builder.query<Account, { id: number }>({
			query: ({ id }) => API_ENDPOINTS.ACCOUNT.BY_ID(id),
			providesTags: (_result, _error, { id }) => [{ type: 'Accounts', id }],
		}),

		// Создание аккаунта
		createAccount: builder.mutation<Account, Partial<Account>>({
			query: (body) => {
				const includeFields = ['name', 'status'];
				const selectedBody = selectFields(body, includeFields);

				return {
					url: API_ENDPOINTS.ACCOUNT.CREATE,
					method: 'POST',
					body: selectedBody,
				};
			},
			invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
		}),

		// Копирование аккаунта
		copyAccount: builder.mutation<Account, Partial<Account> & { id: number }>({
			query: (body) => {
				return {
					url: API_ENDPOINTS.ACCOUNT.COPY(body.id),
					method: 'POST',
					body: { name: null, status: null },
				};
			},
			invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
		}),

		// Обновление аккаунта
		updateAccount: builder.mutation<Account, { id: number; name: string; status: number }>({
			query: (body) => {
				const { id, name, status } = body;

				const filteredBody = {
					name,
					status,
				};

				return {
					url: API_ENDPOINTS.ACCOUNT.UPDATE(id),
					method: 'PUT',
					body: filteredBody,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [{ type: 'Accounts', id }],
		}),

		// Мягкое удаление аккаунта
		softDeleteAccount: builder.mutation<void, { id: string | number }>({
			query: ({ id }) => {
				// Теперь 'id' будет доступен из объекта
				const url = API_ENDPOINTS.ACCOUNT.DELETE(id);
				return {
					url,
					method: 'DELETE',
				};
			},
			invalidatesTags: (_result, _error, { id }) => [{ type: 'Accounts', id }],
		}),
	}),
});

export const {
	useGetAccountsQuery,
	useGetAccountsMetaQuery,
	useGetAccountByIdQuery,
	useCreateAccountMutation,
	useCopyAccountMutation,
	useUpdateAccountMutation,
	useSoftDeleteAccountMutation,
} = accountsApi;
