import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PostCreationModalState {
	futureId: number | null;
	isSubmitted: boolean;
}

const initialState: PostCreationModalState = {
	futureId: null,
	isSubmitted: false,
};

const postCreationModalSlice = createSlice({
	name: 'postCreationModal',
	initialState,
	reducers: {
		setFutureId: (state, action: PayloadAction<number>) => {
			state.futureId = action.payload;
		},
		setIsSubmitted: (state, action: PayloadAction<boolean>) => {
			state.isSubmitted = action.payload;
		},
		clearState: (state) => {
			state.futureId = null;
			state.isSubmitted = false;
		},
	},
});

export const { setFutureId, setIsSubmitted, clearState } = postCreationModalSlice.actions;

export default postCreationModalSlice.reducer;
