// Определяю важные HTTP статус-коды для обработки ответов от сервера

export const HTTP_STATUS_CODES = {
	OK: 200,
	CREATED: 201,
	NO_CONTENT: 204,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	GONE: 410,
	TOO_MANY_REQUESTS: 429,
	INTERNAL_SERVER_ERROR: 500,
	// Другие коды добавлю, если понадобится
};
// Утилиты для проверки типов ошибок
export const isClientError = (status: number) => status >= 400 && status < 500;
export const isServerError = (status: number) => status >= 500;
