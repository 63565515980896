import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Filters, UserAccountPermission } from '@/modules/userAccountPermission/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';

// API для работы с правилами доступа пользователей к аккаунтам
export const userAccountPermissionApi = createApi({
	reducerPath: 'userAccountPermissionApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['UserAccountPermissions'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы правил пользователей
		getUserAccountPermissionsMeta: builder.query<
			{ statuses: string[]; columns: string[] },
			void
		>({
			query: () => API_ENDPOINTS.USER_ACCOUNT_PERMISSION.META,
		}),

		// Получение списка правил для пользователя с фильтрацией, сортировкой и пагинацией
		getUserAccountPermissions: builder.query<
			{ permissions: UserAccountPermission[]; total: number; page: number; limit: number },
			Partial<Filters>
		>({
			query: (filters) => {
				const { user_id } = filters;

				// Проверяем, что user_id передан
				if (!user_id) {
					throw new Error('user_id is required to fetch user account permissions');
				}

				// Определяем правила для фильтрации и сортировки
				const filterRules = {
					permission_type: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.permission_type)
							params.append('permission_type', filters.permission_type);
					},
					account_id: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.account_id)
							params.append('account_id', filters.account_id.toString());
					},
					page: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				// Формируем параметры запроса
				const queryParams = buildQueryParams(filters, filterRules);

				return {
					url: `${API_ENDPOINTS.USER_ACCOUNT_PERMISSION.LIST(user_id)}${queryParams}`,
				};
			},
			// Инвалидируем теги при успешном запросе
			providesTags: (result) =>
				result?.permissions
					? [
							...result.permissions.map(({ id }: UserAccountPermission) => ({
								type: 'UserAccountPermissions' as const,
								id,
							})),
							{ type: 'UserAccountPermissions', id: 'LIST' },
						]
					: [{ type: 'UserAccountPermissions', id: 'LIST' }],
		}),

		// Получение списка правил для аккаунта с фильтрацией, сортировкой и пагинацией
		getAccountPermissions: builder.query<
			{ permissions: UserAccountPermission[]; total: number; page: number; limit: number },
			Partial<Filters>
		>({
			query: (filters) => {
				const { account_id } = filters;

				// Проверяем, что account_id передан
				if (!account_id) {
					throw new Error('account_id is required to fetch account permissions');
				}

				// Определяем правила для фильтрации и сортировки
				const filterRules = {
					permission_type: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.permission_type)
							params.append('permission_type', filters.permission_type);
					},
					user_id: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.user_id)
							params.append('account_id', filters.user_id.toString());
					},
					page: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				// Формируем параметры запроса
				const queryParams = buildQueryParams(filters, filterRules);

				return {
					url: `${API_ENDPOINTS.ACCOUNT_PERMISSION.LIST(account_id)}${queryParams}`,
				};
			},
			// Инвалидируем теги при успешном запросе
			providesTags: (result) =>
				result?.permissions
					? [
							...result.permissions.map(({ id }: UserAccountPermission) => ({
								type: 'UserAccountPermissions' as const,
								id,
							})),
							{ type: 'UserAccountPermissions', id: 'LIST' },
						]
					: [{ type: 'UserAccountPermissions', id: 'LIST' }],
		}),

		// Получение данных правила по ID
		getUserAccountPermissionById: builder.query<
			UserAccountPermission,
			{ user_id: string | number; id: string | number }
		>({
			query: (queryParams) => {
				const { user_id, id: permission_id } = queryParams;

				// Проверяем, что user_id и permission_id переданы
				if (!user_id || !permission_id) {
					throw new Error('Invalid query parameters');
				}

				// Формируем URL для запроса
				return API_ENDPOINTS.USER_ACCOUNT_PERMISSION.BY_ID(
					Number(user_id),
					Number(permission_id),
				);
			},
			// Инвалидируем тег для конкретного правила
			providesTags: (_result, _error, { id }) => [{ type: 'UserAccountPermissions', id }],
		}),

		// Создание нового правила
		createUserAccountPermission: builder.mutation<
			UserAccountPermission,
			{ user_id: string | number; body: Partial<UserAccountPermission> }
		>({
			query: (body) => {
				// Выбираем только необходимые поля для запроса
				const includeFields = ['account_id', 'permission_type', 'user_id'];
				const selectedBody = selectFields(body, includeFields);

				const { user_id, ...serializedBody } = selectedBody;

				return {
					url: API_ENDPOINTS.USER_ACCOUNT_PERMISSION.CREATE(user_id),
					method: 'POST',
					body: serializedBody,
				};
			},
			// Инвалидируем список правил после создания
			invalidatesTags: [{ type: 'UserAccountPermissions', id: 'LIST' }],
		}),

		// Обновление правила
		updateUserAccountPermission: builder.mutation<
			UserAccountPermission,
			{ user_id: string | number; id: string | number; body: Partial<UserAccountPermission> }
		>({
			query: (body) => {
				// Выбираем только необходимые поля для запроса
				const includeFields = ['account_id', 'permission_type', 'user_id', 'id'];
				const selectedBody = selectFields(body, includeFields);

				const { user_id, id, ...serializedBody } = selectedBody;
				return {
					url: API_ENDPOINTS.USER_ACCOUNT_PERMISSION.UPDATE(user_id, id),
					method: 'PUT',
					body: serializedBody,
				};
			},
			// Инвалидируем тег для конкретного правила после обновления
			invalidatesTags: (_result, _error, { id }) => [{ type: 'UserAccountPermissions', id }],
		}),

		// Удаление правила
		deleteUserAccountPermission: builder.mutation<
			void,
			{ user_id: string | number; id: string | number }
		>({
			query: ({ user_id, id }) => {
				const url = API_ENDPOINTS.USER_ACCOUNT_PERMISSION.DELETE(user_id, id);

				return {
					url,
					method: 'DELETE',
				};
			},
			// Инвалидируем тег для конкретного правила после удаления
			invalidatesTags: (_result, _error, { id }) => [{ type: 'UserAccountPermissions', id }],
		}),
	}),
});

// Экспортируем хуки для использования в компонентах
export const {
	useGetUserAccountPermissionsMetaQuery,
	useGetUserAccountPermissionsQuery,
	useGetUserAccountPermissionByIdQuery,
	useCreateUserAccountPermissionMutation,
	useUpdateUserAccountPermissionMutation,
	useDeleteUserAccountPermissionMutation,
	useGetAccountPermissionsQuery,
} = userAccountPermissionApi;
