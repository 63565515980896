import { lazy } from 'react';
import { ROUTES } from '@/shared/constants/routes';
import { Navigate, RouteObject } from 'react-router-dom';

// Ленивая загрузка компонентов
/* eslint-disable react-refresh/only-export-components */
const MainLayout = lazy(() => import('@/shared/layouts/MainLayout'));
const Login = lazy(() => import('@/modules/auth/components/Login/Login'));
const Welcome = lazy(() => import('@/modules/welcome/components/Welcome'));
const PasswordResetRequest = lazy(
	() => import('@/modules/auth/components/PasswordResetRequest/PasswordResetRequest'),
);
const PasswordReset = lazy(() => import('@/modules/auth/components/PasswordReset/PasswordReset'));
const NotFound = lazy(() => import('@/shared/components/NotFound/NotFound'));
const Users = lazy(() => import('@/modules/users/components/Users/Users'));
const UserPermissions = lazy(
	() =>
		import('@/modules/userAccountPermission/components/UserPermissions/UserAccountPermission'),
);
const UserApiTokens = lazy(
	() => import('@/modules/userApiToken/components/UserApiTokens/UserApiTokens'),
);

const Accounts = lazy(() => import('@/modules/accounts/components/Accounts/Accounts'));
const Projects = lazy(() => import('@/modules/accounts/components/Projects/Projects'));
const Integrations = lazy(() => import('@/modules/accounts/components/Integrations/Integrations'));
const Prompts = lazy(() => import('@/modules/accounts/components/Prompts/Prompts'));
const Triggers = lazy(() => import('@/modules/accounts/components/Triggers/Triggers'));
const Tasks = lazy(() => import('@/modules/accounts/components/Tasks/Tasks'));
const GlobalVariables = lazy(
	() => import('@/modules/globalVariable/components/GlobalVariable/GlobalVariable'),
);
const CalltouchAccountSites = lazy(
	() => import('@/modules/calltouch/components/Calltouch/Calltouch'),
);
const Calls = lazy(() => import('@/modules/accounts/components/Calls/Calls'));
const AiCompletions = lazy(
	() => import('@/modules/accounts/components/AiCompletions/AiCompletions'),
);

// Публичные маршруты
export const publicRoutes: RouteObject[] = [
	// Логин
	{ path: ROUTES.LOGIN, element: <Login /> },
	// Сброс пароля
	{ path: ROUTES.RESET_PASSWORD_REQUEST, element: <PasswordResetRequest /> },
	{ path: ROUTES.RESET_PASSWORD, element: <PasswordReset /> },
	// Страница 404
	{ path: ROUTES.NOT_FOUND, element: <NotFound /> },
];

// Приватные маршруты
export const privateRoutes: RouteObject[] = [
	{
		path: '/',
		element: <MainLayout />,
		children: [
			// Редирект на приветствие
			{ index: true, element: <Navigate to={ROUTES.WELCOME} replace /> },
			// Приветствие
			{ path: ROUTES.WELCOME, element: <Welcome /> },
			// Пользователи
			{ path: ROUTES.USERS, element: <Users /> },
			// Пользовательские права
			{ path: ROUTES.USER_ACCOUNT_PERMISSIONS, element: <UserPermissions /> },
			// Пользовательские токены
			{ path: ROUTES.USER_API_TOKENS, element: <UserApiTokens /> },
			// Аккаунты
			{ path: ROUTES.ACCOUNTS, element: <Accounts /> },
			// Проекты
			{ path: ROUTES.PROJECTS, element: <Projects /> },
			// Интеграции
			{ path: ROUTES.INTEGRATIONS, element: <Integrations /> },
			// Промпты
			{ path: ROUTES.PROMPTS, element: <Prompts /> },
			// Триггеры
			{ path: ROUTES.TRIGGERS, element: <Triggers /> },
			// Задания
			{ path: ROUTES.TASKS, element: <Tasks /> },
			// Calltouch
			{ path: ROUTES.CALLTOUCH, element: <CalltouchAccountSites /> },
			// Звонки
			{ path: ROUTES.CALLS, element: <Calls /> },
			// AI Обработки
			{ path: ROUTES.AI_COMPLETIONS, element: <AiCompletions /> },
			// Глобальные переменные
			{ path: ROUTES.GLOBAL_VARIABLES, element: <GlobalVariables /> },
		],
	},
];
