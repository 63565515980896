import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Filters, UserApiToken } from '@/modules/userApiToken/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import selectFields from '@/shared/utils/selectFields';

// API для работы с токенами юзера
export const userApiTokensApi = createApi({
	reducerPath: 'userApiTokensApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['UserApiTokens'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы API токенов пользователей
		getUserApiTokensMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.USER_API_TOKENS.META,
		}),

		// Получение списка токенов
		getUserApiTokens: builder.query<{ user_api_token_list: UserApiToken[] }, Partial<Filters>>({
			query: (filters) => {
				const { user_id } = filters;

				// Проверяем, что user_id передан
				if (!user_id) {
					throw new Error('user_id is required to fetch user account permissions');
				}

				return {
					url: API_ENDPOINTS.USER_API_TOKENS.LIST(user_id),
				};
			},
			providesTags: (result) =>
				result?.user_api_token_list
					? [
							...result.user_api_token_list.map(({ id }: UserApiToken) => ({
								type: 'UserApiTokens' as const,
								id,
							})),
							{ type: 'UserApiTokens', id: 'LIST' },
						]
					: [{ type: 'UserApiTokens', id: 'LIST' }],
		}),

		// Получение данных токена по ID
		getUserApiTokenById: builder.query<
			UserApiToken,
			{ user_id: string | number; id: string | number }
		>({
			query: (queryParams) => {
				const { user_id, id: id } = queryParams;
				if (!user_id || !id) {
					throw new Error('Invalid query parameters');
				}

				return API_ENDPOINTS.USER_API_TOKENS.BY_ID(Number(user_id), Number(id));
			},
			providesTags: (_result, _error, { id }) => [{ type: 'UserApiTokens', id }],
		}),

		// Создание нового токена
		createUserApiToken: builder.mutation<
			UserApiToken,
			{ user_id: string | number; body: Partial<UserApiToken> }
		>({
			query: (body) => {
				// Выбираем только необходимые поля для запроса
				const includeFields = ['user_id', 'token', 'expiration_date'];
				const selectedBody = selectFields(body, includeFields);

				const { user_id, ...serializedBody } = selectedBody;
				serializedBody.status = 1;
				serializedBody.expiration_date =
					serializedBody.expiration_date.format('YYYY-MM-DD');

				return {
					url: API_ENDPOINTS.USER_API_TOKENS.CREATE(user_id),
					method: 'POST',
					body: serializedBody,
				};
			},
			// Инвалидируем список токенов после создания
			invalidatesTags: [{ type: 'UserApiTokens', id: 'LIST' }],
		}),

		// Обновление токена
		updateUserApiToken: builder.mutation<
			UserApiToken,
			{ user_id: string | number; id: string | number; body: Partial<UserApiToken> }
		>({
			query: (body) => {
				// Выбираем только необходимые поля для запроса
				const includeFields = ['user_id', 'id', 'status'];
				const selectedBody = selectFields(body, includeFields);

				const { user_id, id, ...serializedBody } = selectedBody;
				return {
					url: API_ENDPOINTS.USER_API_TOKENS.UPDATE(user_id, id),
					method: 'PUT',
					body: serializedBody,
				};
			},
			// Инвалидируем тег для конкретного токена после обновления
			invalidatesTags: (_result, _error, { id }) => [{ type: 'UserApiTokens', id }],
		}),

		// Удаление правила
		deleteUserApiToken: builder.mutation<
			void,
			{ user_id: string | number; id: string | number }
		>({
			query: ({ user_id, id }) => {
				const url = API_ENDPOINTS.USER_API_TOKENS.DELETE(user_id, id);

				return {
					url,
					method: 'DELETE',
				};
			},
			// Инвалидируем тег для конкретного токена после удаления
			invalidatesTags: (_result, _error, { id }) => [{ type: 'UserApiTokens', id }],
		}),

		// Генерация нового токена
		generateUserApiToken: builder.mutation<{ token: string }, void>({
			query: () => ({
				url: API_ENDPOINTS.USER_API_TOKENS.GENERATE,
				method: 'GET',
			}),
		}),
	}),
});

// Экспортируем хуки для использования в компонентах
export const {
	useGetUserApiTokensMetaQuery,
	useGetUserApiTokensQuery,
	useGetUserApiTokenByIdQuery,
	useCreateUserApiTokenMutation,
	useUpdateUserApiTokenMutation,
	useDeleteUserApiTokenMutation,
	useGenerateUserApiTokenMutation,
} = userApiTokensApi;
