import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Task, TaskFilters } from '@/modules/accounts/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';
import { Dayjs } from 'dayjs';

// API для работы с заданиями
export const tasksApi = createApi({
	reducerPath: 'tasksApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Tasks'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы с заданиями
		getTasksMeta: builder.query<{ statuses: string[]; types: string[] }, void>({
			query: () => API_ENDPOINTS.TASK.META,
		}),

		// Получение списка заданий с фильтрацией, сортировкой и пагинацией
		getTasks: builder.query<
			{ tasks: Task[]; total: number; page: number; limit: number },
			TaskFilters
		>({
			query: (filters) => {
				const { account_id } = filters;

				// Проверка на наличие account_id
				if (!account_id) {
					throw new Error('account_id is required to fetch tasks');
				}

				const filterRules = {
					task_type_id: (filters: TaskFilters, params: URLSearchParams) => {
						if (filters.task_type_id)
							params.append('task_type_id', filters.task_type_id.toString());
					},
					project_id: (filters: TaskFilters, params: URLSearchParams) => {
						if (filters.project_id)
							params.append('project_id', filters.project_id.toString());
					},
					status: (filters: TaskFilters, params: URLSearchParams) => {
						if (filters.status) params.append('status', filters.status.toString());
					},
					page: (filters: TaskFilters, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: TaskFilters, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: TaskFilters, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				const queryParams = buildQueryParams(filters, filterRules);

				return {
					url: `${API_ENDPOINTS.TASK.LIST(account_id)}${queryParams}`,
				};
			},
			providesTags: (result) =>
				result?.tasks
					? [
							...result.tasks.map(({ id }: Task) => ({
								type: 'Tasks' as const,
								id,
							})),
							{ type: 'Tasks', id: 'LIST' },
						]
					: [{ type: 'Tasks', id: 'LIST' }],
		}),

		getTaskById: builder.query<Task, { account_id: string; id: string }>({
			query: (queryParams) => {
				const { account_id, id: task_id } = queryParams;

				if (!account_id || !task_id) {
					throw new Error('Invalid query parameters');
				}

				// Формируем запрос с осмысленными именами переменных
				return API_ENDPOINTS.TASK.BY_ID(Number(account_id), Number(task_id));
			},
			providesTags: (_result, _error, { id }) => [{ type: 'Tasks', id }],
		}),

		// Создание нового задания
		processingTask: builder.mutation<
			Task,
			{ account_id: string | number; body: Partial<Task> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'project_id',
					'period',
					're_save_existed_ai_completion',
				];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, project_id, period, ...serializedBody } = selectedBody;
				const [from, to] = period as [Dayjs, Dayjs];

				if (!serializedBody['re_save_existed_ai_completion']) {
					serializedBody['re_save_existed_ai_completion'] = false;
				}

				const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.TASK.CREATE(account_id, project_id),
					method: 'POST',
					body: {
						...serializedBody,
						from: from.format(dateFormat),
						to: to.format(dateFormat),
					},
				};
			},
			invalidatesTags: [{ type: 'Tasks', id: 'LIST' }],
		}),
	}),
});

export const {
	useGetTasksQuery,
	useGetTasksMetaQuery,
	useGetTaskByIdQuery,
	useProcessingTaskMutation,
} = tasksApi;
