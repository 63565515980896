import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';

import { Filters, GlobalVariable } from '../types';
import selectFields from '@/shared/utils/selectFields';

// API для работы с GlobalVariable
export const globalVariableApi = createApi({
	reducerPath: 'globalVariableApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['GlobalVariable'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы глобальных переменных

		getGlobalVariableMeta: builder.query<unknown, void>({
			query: () => API_ENDPOINTS.GLOBAL_VARIABLE.META,
		}),

		// Получение списка глобальных переменных с фильтрацией, сортировкой и пагинацией
		getGlobalVariables: builder.query<
			{ variables: GlobalVariable[]; total: number; page: number; limit: number },
			Partial<Filters>
		>({
			query: (filters) => {
				const filterRules = {
					key: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.key) params.append('key', filters.key);
					},
					type: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.type !== undefined)
							params.append('type', filters.type.toString());
					},
					page: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				const queryParams = buildQueryParams(filters, filterRules);
				return {
					url: `${API_ENDPOINTS.GLOBAL_VARIABLE.LIST}${queryParams}`,
				};
			},
			providesTags: (result) =>
				result?.variables
					? [
							...result.variables.map(({ key }: GlobalVariable) => ({
								type: 'GlobalVariable' as const,
								id: key,
							})),
							{ type: 'GlobalVariable', id: 'LIST' },
						]
					: [{ type: 'GlobalVariable', id: 'LIST' }],
		}),

		// Получение данных о глобальной переменной по ключу
		getGlobalVariableByKey: builder.query<GlobalVariable, string | number>({
			query: (key) => API_ENDPOINTS.GLOBAL_VARIABLE.GET_BY_KEY(key),
			providesTags: (_result, _error, key) => [{ type: 'GlobalVariable', id: key }],
		}),

		// Создание новой глобальной переменной
		createGlobalVariable: builder.mutation<GlobalVariable, Partial<GlobalVariable>>({
			query: (body) => {
				const includeFields = ['key', 'type', 'value'];
				const selectedBody = selectFields(body, includeFields);

				return {
					url: API_ENDPOINTS.GLOBAL_VARIABLE.CREATE,
					method: 'POST',
					body: selectedBody,
				};
			},
			invalidatesTags: [{ type: 'GlobalVariable', id: 'LIST' }],
		}),

		// Обновление значения глобальной переменной по ключу
		updateGlobalVariable: builder.mutation<
			GlobalVariable,
			{ key: string | number; body: Partial<GlobalVariable> }
		>({
			query: (body) => {
				const includeFields = ['key', 'value', 'id'];
				const selectedBody = selectFields(body, includeFields);

				const { id, ...serializedBody } = selectedBody;

				return {
					url: API_ENDPOINTS.GLOBAL_VARIABLE.UPDATE(id),
					method: 'PUT',
					body: serializedBody,
				};
			},
			invalidatesTags: (_result, _error, { key }) => [
				{ type: 'GlobalVariable', id: key },
				{ type: 'GlobalVariable', id: 'LIST' },
			],
		}),

		// Удаление глобальной переменной по ключу
		deleteGlobalVariable: builder.mutation<void, { id: number }>({
			query: (args) => {
				const { id } = args;
				const url = API_ENDPOINTS.GLOBAL_VARIABLE.DELETE(id);
				return {
					url,
					method: 'DELETE',
				};
			},
			invalidatesTags: (_result, _error, args) => {
				const { id } = args;

				return [
					{ type: 'GlobalVariable', id: id },
					{ type: 'GlobalVariable', id: 'LIST' },
				];
			},
		}),
	}),
});

export const {
	useGetGlobalVariableMetaQuery,
	useGetGlobalVariablesQuery,
	useGetGlobalVariableByKeyQuery,
	useCreateGlobalVariableMutation,
	useUpdateGlobalVariableMutation,
	useDeleteGlobalVariableMutation,
} = globalVariableApi;
