import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CallFilters } from '@/modules/accounts/types.ts';
import { buildQueryParams } from '@/shared/utils/buildQueryParams.ts';

interface CallResponse {
	id: number;
	external_call_id: string;
	integration_id: number;
	data: any;
	text: any;
	url: string;
	call_datetime: string;
	created_at: string;
	updated_at: string;
	account_id: number;
	call_data_type_id: number;
	phone: string;
	ai_completions_ids: any;
}

export const callsApi = createApi({
	reducerPath: 'callsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Calls'],
	endpoints: (builder) => ({
		// Получение метаинформации для звонков
		getCallsMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.CALL.META,
		}),

		// Получение списка звонков для проекта
		getCalls: builder.query<
			{ calls: CallResponse[]; total: number; page: number; limit: number },
			{
				account_id: string | number;
				project_id: string | number;
				limit?: number;
				page?: number;
			} & CallFilters
		>({
			query: (filters) => {
				const { account_id, project_id } = filters;

				if (!account_id && !project_id) {
					throw new Error('account_id and project_id is required to fetch calls');
				}

				const filterRules = {
					page: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					id: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.id) params.append('id', filters.id.toString());
					},
					external_call_id: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.external_call_id)
							params.append('external_call_id', filters.external_call_id.toString());
					},
					phone: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.phone) params.append('phone', filters.phone.toString());
					},
					ai_completion_ids: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.ai_completion_ids)
							params.append(
								'ai_completion_ids',
								filters.ai_completion_ids.toString(),
							);
					},
					call_data_type: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.call_data_type)
							params.append('call_data_type', filters.call_data_type.toString());
					},
					call_datetime: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.call_datetime)
							params.append('call_datetime', filters.call_datetime.toString());
					},
					url: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.url) params.append('url', filters.url.toString());
					},
					created_at: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.created_at)
							params.append('created_at', filters.created_at.toString());
					},
					updated_at: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.updated_at)
							params.append('updated_at', filters.updated_at.toString());
					},
					from: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.from) params.append('from', filters.from.toString());
					},
					to: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.to) params.append('to', filters.to.toString());
					},
					sort: (filters: Partial<CallFilters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				const queryParams = buildQueryParams(filters, filterRules);

				const url = `${API_ENDPOINTS.CALL.LIST(account_id, project_id)}${queryParams}`;
				return { url };
			},
			providesTags: (result) =>
				result?.calls
					? [
							...result.calls.map(({ id }: CallResponse) => ({
								type: 'Calls' as const,
								id,
							})),
							{ type: 'Calls', id: 'LIST' },
						]
					: [{ type: 'Calls', id: 'LIST' }],
		}),

		// Получение данных звонка по ID
		getCallById: builder.query<
			CallResponse,
			{
				account_id: string | number;
				project_id: string | number;
				call_id: string | number;
			}
		>({
			query: ({ account_id, project_id, call_id }) => {
				if (call_id === undefined || account_id === undefined || project_id === undefined) {
					throw new Error('call_id, account_id and project_id is required');
				}

				return API_ENDPOINTS.CALL.BY_ID(account_id, project_id, call_id);
			},
			providesTags: (_result, _error, { call_id }) => [{ type: 'Calls', id: call_id }],
		}),
	}),
});

export const { useGetCallsMetaQuery, useGetCallsQuery, useGetCallByIdQuery } = callsApi;
